<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex flex-wrap">
          <div class="xl:col-5 col-12">
            <Panel class="pb-3" header="Permission for companies">
              <MultiSelect
                v-model="selectedCompanies" :options="companies" optionLabel="name" optionValue="id"
                placeholder="Select Countries" :filter="true" :style="{width: '100%'}"
                id="selected-companies" class="multiselect-custom"
                @change="saveCompanies"
              ></MultiSelect>
            </Panel>
            <Panel header="Edit Payment Method">
              <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">

                <!-- Min amount($) -->
                <div class="field pb-2 pt-2">
                  <div class="p-float-label">
                    <InputText id="min-amount" v-model="v$.form.minAmount.$model" :class="{'p-invalid':v$.form.minAmount.$invalid && submitted}"/>
                    <label for="min-amount" :class="{'p-error':v$.form.minAmount.$invalid && submitted}">Min amount($)</label>
                  </div>

                  <small v-if="(v$.form.minAmount.$invalid && submitted) || v$.form.minAmount.$pending.$response" class="p-error">
                    {{ v$.form.minAmount.required.$message.replace('Value', 'Min amount') }}
                  </small>
                </div>

                <Button type="submit" label="Submit" class="mt-2"/>
              </form>
            </Panel>
          </div>
          <div class="xl:col-7 col-12">
            <Panel id="zelle-payment-request-table" header="">
              <template #header>
                  <span class="p-panel-title">Zelle Payment Requests</span>
              </template>
              <template #icons>
                  <span class="p-input-icon-left mt-2 xl:mt-0">
                    <i class="pi pi-search" />
                    <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
                  </span>
              </template>
              <ZellePaymentRequestTable/>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@store";
import ZellePaymentRequestTable from "@components/zelle/ZellePaymentRequestTable";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import companyResource from "@http/CompanyResource";
import {zelle} from "@http/PaymentMethodResource";
import dataTable from "@/mixins/dataTable";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  components: {
    ZellePaymentRequestTable
  },

  data() {
    return {
      selectedCompanies: [],
      companies: [],
      form: {
        minAmount: null,
      },
      submitted: false,
    }
  },

  validations() {
    return {
      form: {
        minAmount: {required},
      }
    }
  },

  methods: {
    saveCompanies() {
      zelle.savePermissionCompany({companies: this.selectedCompanies})
        .then(() => {
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
          this.$toast.add(toastParamBuilder.success('Published'));
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },

    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      zelle.update({
        min_amount: this.form.minAmount,
      })
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Successfully!'));
          this.resetForm();
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },

    resetForm() {
      this.submitted = false;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let companies = await companyResource.allCompanies();
      let form = await zelle.get();
      let selectedCompanies = await zelle.getPermissionCompanies();
      await store.dispatch('zellePaymentRequest/zellePaymentRequestsAllCompanies', to.query);
      next((vm) => {
        vm.companies = companies.data;
        vm.selectedCompanies = selectedCompanies.data;
        vm.form.minAmount = form.data.min_amount;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('zellePaymentRequest/zellePaymentRequestsAllCompanies', to.query);
    await zelle.get()
      .then(({data}) => {
        this.form.minAmount = data.min_amount;
      })
  },
}
</script>

<style lang="scss" scoped>
::v-deep(#zelle-payment-request-table .p-panel-content) {
  padding: 0;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

</style>
<style>
  .card .p-panel-header {
    flex-wrap: wrap;
  }
</style>
