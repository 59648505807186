<template>
  <div>
    <Dialog v-model:visible="dialog" :style="{width: '450px'}" header="Rejection reason" :modal="true" class="p-fluid" @hide="closeDialog">
      <!-- Amount -->
      <div class="field pb-2 pt-2">
        <div class="p-float-label">
          <InputText id="amount" v-model="v$.form.amount.$model"
                     :class="{'p-invalid':v$.form.amount.$invalid && submitted}"/>
          <label for="amount" :class="{'p-error':v$.form.amount.$invalid && submitted}">Amount*</label>
        </div>

        <span v-if="v$.form.amount.$error && submitted">
          <span id="email-error" v-for="(error, index) of v$.form.amount.$errors" :key="index">
            <small class="p-error">{{ error.$message.replace('Value', 'Amount') }}</small>
          </span>
        </span>
      </div>

      <!-- Transaction Id -->
      <div class="field pb-2 pt-2">
        <div class="p-float-label">
          <InputText id="transactionId" v-model="v$.form.transactionId.$model"
                     :class="{'p-invalid':v$.form.transactionId.$invalid && submitted}"/>
          <label for="transactionId" :class="{'p-error':v$.form.transactionId.$invalid && submitted}">Transaction Id*</label>
        </div>

        <small
          v-if="(v$.form.transactionId.$invalid && submitted) || v$.form.transactionId.$pending.$response"
          class="p-error">{{ v$.form.transactionId.required.$message.replace('Value', 'Transaction Id') }}
        </small>
      </div>

      <p>Min amount: <b>{{ minAmount }} $</b></p>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog"/>
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="handleSubmit(!v$.$invalid)"/>
      </template>
    </Dialog>

    <DataTable
      :value="getZellePaymentRequests" :lazy="true" :paginator="true" :first="getPagination?.from-1"
      :rows="getPagination?.per_page ?? 25" ref="dt" dataKey="id"
      :totalRecords="getPagination.total" :loading="loading" @page="onPage($event)"
      @sort="onSort($event)" :sortField="sort" :sortOrder="order"
      responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      :globalFilterFields="['status']" v-model:filters="filters" filterDisplay="row"
      @filter="onFilter($event, ['status'])"
    >
      <template #empty>
        Not found.
      </template>

      <!-- Company Name -->
      <Column field="company" header="Company Name" :sortable="true"/>

      <!-- Amount -->
      <Column field="amount" header="Amount" :sortable="true"/>

      <!-- Transaction ID -->
      <Column field="transaction_id" header="Transaction ID" :sortable="true"/>

      <!-- Status -->
      <Column field="status" header="Status" :showFilterMenu="false" :showClearButton="false">
        <template #body="{data}">
        <span :class="'product-badge ' + (statuses[data.status].class)">
          {{ statuses[data.status].label.toUpperCase() }}
        </span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" placeholder="Status"
                    class="p-column-filter">
            <template #value="slotProps">
            <span :class="'product-badge ' + (slotProps.value.class)"
                  v-if="slotProps.value">{{ slotProps.value.label.toUpperCase() }}</span>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
            <template #option="slotProps">
            <span
              :class="'product-badge ' + slotProps.option.class">{{ slotProps.option.label.toUpperCase() }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>

      <!-- Actions -->
      <Column v-if="isSuperAdmin" headerStyle="min-width:13rem;">
        <template #body="{data}">
          <Button
            v-if="data.status === 0"
            icon="pi pi-check" class="p-button-rounded p-button-success mr-2"
            @click="handleApprove(data.id)"
          />
          <Button
            v-if="data.status === 0"
            icon="pi pi-times" class="p-button-rounded p-button-danger mr-2"
            @click="handleReject(data.id)"
          />
          <Button
            v-if="data.status === 0"
            icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
            @click="handleEditModal(data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import dataTable from "@/mixins/dataTable";
import {mapActions, mapGetters} from "vuex";
import {FilterMatchMode} from "primevue/api";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import user from "@/models/User"
import {minValue, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {zelle} from "@http/PaymentMethodResource";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  data() {
    return {
      minAmount: null,
      dialog: false,
      id: null,
      form: {
        amount: null,
        message: null,
      },
      submitted: false,
      statuses: [
        {id: 0, label: 'pending', class: 'status-in progress'},
        {id: 1, label: 'approved', class: 'status-success'},
        {id: 2, label: 'rejected', class: 'status-rejected'},
      ],
      filters: {
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
    }
  },

  validations() {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(this.minAmount),
        },
        transactionId: {required},
      }
    }
  },

  created() {
    zelle.get()
      .then((response) => {
        this.minAmount = response.data?.min_amount;
      })
      .catch(error => {
        this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });

    this.filters.status.value = this.statuses[this.$route.query?.status ?? 0];
  },

  methods: {
    ...mapActions({
      approve: 'zellePaymentRequest/approve',
      reject: 'zellePaymentRequest/reject',
      show: 'zellePaymentRequest/show',
      update: 'zellePaymentRequest/update',
    }),
    handleApprove(id) {
      this.approve(id)
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Approved'));
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },
    handleReject(id) {
      this.reject(id)
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Approved'));
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              _r: Date.now(),
            }
          });
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },
    handleEditModal(id) {
      this.show(id)
        .then((response) => {
          this.form.amount = response.data?.amount;
          this.form.transactionId = response.data?.transaction_id;
          this.id = id;
          this.dialog = true;
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
        });
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.update({
        id: this.id,
        data: this.form
      })
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Successfully!'));
          this.closeDialog();
          this.resetForm();
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },
    resetForm() {
      this.form.amount = null;
      this.form.amount = null;
      this.form.transactionId = null;
      this.submitted = false;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },
    closeDialog() {
      this.id = null;
      this.dialog = false;
    },
  },

  computed: {
    ...mapGetters({
      userRole: "auth/getRole",
      getZellePaymentRequests: "zellePaymentRequest/getZellePaymentRequests",
      getPagination: "zellePaymentRequest/getPagination"
    }),
    isSuperAdmin() {
      return this.userRole === user.ROLE_SUPER_ADMIN
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';
</style>
